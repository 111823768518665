import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231')
];

export const server_loads = [0,24,27,26,28,29,30,31,33,36,2,4,6,7,8,9,10,11,12,13,14,15,16,17,23,18,20];

export const dictionary = {
		"/": [~40],
		"/admin": [~124,[24],[25]],
		"/admin/companies": [~125,[24],[25]],
		"/admin/companies/[companySlug]": [~126,[24],[25]],
		"/admin/companies/[companySlug]/edit": [~127,[24],[25]],
		"/admin/companies/[companySlug]/edit/accesses": [128,[24],[25]],
		"/admin/companies/[companySlug]/edit/licenses": [129,[24],[25]],
		"/admin/companies/[companySlug]/projects": [~130,[24],[25]],
		"/admin/companies/[companySlug]/users": [~131,[24],[25]],
		"/admin/country-risk": [~132,[24],[25]],
		"/admin/devtools": [133,[24],[25]],
		"/admin/devtools/details": [~134,[24],[25]],
		"/admin/emails": [~135,[24],[25]],
		"/admin/emails/email-dashboard": [~136,[24],[25]],
		"/admin/event-log": [~137,[24],[25]],
		"/admin/licenses": [138,[24],[25]],
		"/admin/licenses/susan": [~139,[24],[25]],
		"/admin/merge": [~140,[24],[25]],
		"/admin/merge/[fromId]/[toId]": [~141,[24],[25]],
		"/admin/merge/[fromId]/[toId]/dry-run-successful": [~142,[24],[25]],
		"/admin/merge/[fromId]/[toId]/merge-successful": [~143,[24],[25]],
		"/admin/new-company": [144,[24],[25]],
		"/admin/questions": [~145,[24],[25]],
		"/admin/questions/add": [~157,[24],[25]],
		"/admin/questions/options": [158,[24],[25]],
		"/admin/questions/options/add": [~162,[24],[25]],
		"/admin/questions/options/[optionId]": [~159,[24],[25]],
		"/admin/questions/options/[optionId]/edit": [~160,[24],[25]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~161,[24],[25]],
		"/admin/questions/restructure": [~163,[24],[25]],
		"/admin/questions/sets": [~164,[24],[25]],
		"/admin/questions/sets/[questionSetId]": [~165,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/access": [~166,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/danger-zone": [~167,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~168,[24,27],[25]],
		"/admin/questions/sets/[questionSetId]/print": [~169],
		"/admin/questions/[questionId]": [146,[24,26],[25]],
		"/admin/questions/[questionId]/danger-zone": [~147,[24,26],[25]],
		"/admin/questions/[questionId]/details": [~148,[24,26],[25]],
		"/admin/questions/[questionId]/edit": [149,[24,26],[25]],
		"/admin/questions/[questionId]/follow-ups": [~150,[24,26],[25]],
		"/admin/questions/[questionId]/localizations": [151,[24,26],[25]],
		"/admin/questions/[questionId]/localizations/[locale]": [~152,[24,26],[25]],
		"/admin/questions/[questionId]/options": [~153,[24,26],[25]],
		"/admin/questions/[questionId]/scores": [~154,[24,26],[25]],
		"/admin/questions/[questionId]/ungc": [~155,[24,26],[25]],
		"/admin/questions/[questionId]/update-ungc": [~156,[24,26],[25]],
		"/admin/sent-emails/[email]/[emailType]": [~170],
		"/admin/suggested-follow-ups": [~171,[24],[25]],
		"/admin/suggested-follow-ups/[questionId]": [~172,[24,28],[25]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~173,[24,28],[25]],
		"/admin/taxonomy": [174,[24,29],[25]],
		"/admin/taxonomy/version": [195,[24,29],[25]],
		"/admin/taxonomy/version/[versionId]": [~196,[24,29],[25]],
		"/admin/taxonomy/year": [~197,[24,29],[25]],
		"/admin/taxonomy/[versionId]": [175,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activities": [176,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~177,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/activity-based": [~178,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/categories": [~179,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/categories/edit": [~180,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/company-wide": [~181,[24,29,30,31],[25]],
		"/admin/taxonomy/[versionId]/company-wide/text": [~182,[24,29,30,31],[25]],
		"/admin/taxonomy/[versionId]/expiration": [~183,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/kpi-texts": [184,[24,29,30,32],[25]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~185,[24,29,30,32],[25]],
		"/admin/taxonomy/[versionId]/question-sets": [~189,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~190,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~186,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~187,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~188,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/reusable-group": [~191,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~192,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/sectors": [193,[24,29,30],[25]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~194,[24,29,30],[25]],
		"/admin/transparency-act-steps": [~198,[24],[25]],
		"/admin/transparency-act-steps/add": [202,[24],[25]],
		"/admin/transparency-act-steps/checklist-item": [~203,[24],[25]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~204,[24],[25]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~205,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]": [~199,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [200,[24],[25]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~201,[24],[25]],
		"/admin/users": [~206,[24],[25]],
		"/admin/users/[userId]": [~207,[24],[25]],
		"/admin/users/[userId]/edit": [~208,[24],[25]],
		"/admin/workers": [~209,[24,33],[25]],
		"/data-processing-agreement": [210,[34]],
		"/login": [211],
		"/logout": [212],
		"/maintenance": [213],
		"/notifications": [~214,[35]],
		"/survey-invitation/[invitationId]": [~215,[36]],
		"/survey-invitation/[invitationId]/access-requested": [~216,[36]],
		"/survey-invitation/[invitationId]/company-created": [~217,[36]],
		"/survey-invitation/[invitationId]/declined": [219,[36]],
		"/survey-invitation/[invitationId]/decline": [218,[36]],
		"/survey-invitation/[invitationId]/forwarded": [221,[36]],
		"/survey-invitation/[invitationId]/forward": [220,[36]],
		"/survey-invitation/[invitationId]/new-company": [~222,[36]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [223,[36]],
		"/survey-invitation/[invitationId]/no-access": [~224,[36]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~225,[36]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~226,[36]],
		"/survey-invitation/[invitationId]/select-company": [~227,[36]],
		"/terms-of-use": [228,[37]],
		"/user": [229,[38],[39]],
		"/user/access-tokens": [~230,[38],[39]],
		"/user/access-tokens/create": [~231,[38],[39]],
		"/[companySlug]": [~41,[2],[3]],
		"/[companySlug]/admin/add-company": [~42,[2],[3]],
		"/[companySlug]/company": [~43,[2,4],[3]],
		"/[companySlug]/company/accesses": [~44,[2,4],[3]],
		"/[companySlug]/company/assessments": [~45,[2,4],[3]],
		"/[companySlug]/company/documents": [~46,[2,4],[3]],
		"/[companySlug]/company/edit": [47,[2,4],[3]],
		"/[companySlug]/company/licenses": [~48,[2,4],[3]],
		"/[companySlug]/company/overview": [~49,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~50,[2,4],[3]],
		"/[companySlug]/company/settings": [~51,[2,4,5],[3]],
		"/[companySlug]/company/settings/login": [52,[2,4,5],[3]],
		"/[companySlug]/company/settings/spend": [~53,[2,4,5],[3]],
		"/[companySlug]/company/settings/tags": [~54,[2,4,5],[3]],
		"/[companySlug]/company/users": [~55,[2,4],[3]],
		"/[companySlug]/customers": [~56,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~57,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [58,[2,6],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~59,[2,6],[3]],
		"/[companySlug]/follow-ups": [60,[2,7],[3]],
		"/[companySlug]/follow-ups/received": [~61,[2,7],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~62,[2],[3]],
		"/[companySlug]/follow-ups/sent": [~63,[2,7],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~64,[2],[3]],
		"/[companySlug]/projects": [~65,[2,8],[3]],
		"/[companySlug]/projects/add": [~73,[2,8],[3]],
		"/[companySlug]/projects/add/preview": [~74,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(main)": [66,[2,8,9],[3]],
		"/[companySlug]/projects/[projectId]/(main)/chain/[chainId]": [~67,[2,8,9],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/edit-suppliers-list": [~68,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview": [~69,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview/print": [~70],
		"/[companySlug]/projects/[projectId]/(sub-pages)/send-survey": [~71,[2,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/settings": [~72,[2,8],[3]],
		"/[companySlug]/relogin": [~75],
		"/[companySlug]/saq/[questionSetId]": [~76,[10]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~77,[10]],
		"/[companySlug]/saq/[questionSetId]/finish": [~78,[11]],
		"/[companySlug]/suppliers": [~79,[2,12],[3]],
		"/[companySlug]/suppliers/add": [~91,[2,12],[3]],
		"/[companySlug]/suppliers/upload": [~92,[2,12],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~93,[2,12],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~94,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~80,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~81,[2,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [82,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~83,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~84,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~85,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [86,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~87,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~88,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~89,[2,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~90],
		"/[companySlug]/surveys": [~95,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~96,[14]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~97],
		"/[companySlug]/surveys/[surveyId]/answer": [~98,[14,15]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~99,[14,15]],
		"/[companySlug]/surveys/[surveyId]/finish": [~100,[16]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~101,[16]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~102],
		"/[companySlug]/taxonomy": [~103,[17]],
		"/[companySlug]/taxonomy/overview": [~122,[17,23]],
		"/[companySlug]/taxonomy/overview/import-report": [~123,[17,23]],
		"/[companySlug]/taxonomy/[reportId]": [104,[18]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~105,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~106,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/documents": [~107,[18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~108,[18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~109,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~110,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~111,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~113,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [114,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~112,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~115,[18]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~116,[18]],
		"/[companySlug]/taxonomy/[reportId]/report": [117,[18]],
		"/[companySlug]/taxonomy/[reportId]/start": [~118,[18]],
		"/[companySlug]/taxonomy/[reportId]/summary": [119,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~120,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~121,[18,22]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';